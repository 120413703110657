<template>
  <div>
    <b-card>
      <b-overlay
        :show="loadingPrograms"
        fullscreen
      >
        <b-card-title>Programs</b-card-title>
        <div
          v-if="programs.length > 0"
          id="external-events"
          class="d-flex mb-2"
          style="gap: 10px"
        >
          <div
            v-for="item in programs"
            :key="item.id"
            class="p-1 fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event cursor-pointer"
          >
            <div class="fc-event-main">
              {{ item.name }}
            </div>
          </div>
        </div>
      </b-overlay>
      <div class="row justify-content-between align-items-center mb-1">
        <div />

        <div>
          <b-button
            class="mr-1"
            variant="primary"
            @click="saveSchedule"
          >
            Save Changes
          </b-button>
        </div>
      </div>
      <div>
        <FullCalendar
          ref="fullCalendar"
          :options="calendarOptions"
        />
      </div>
    </b-card>
    <b-modal
      id="my-modal"
      @ok="handleModal"
    >
      <label for="startTime">Start Time</label>
      <b-form-timepicker
        id="startTime"
        v-model="modal.startTime"
        locale="en"
      />
      <label for="endTime">End Time</label>
      <b-form-timepicker
        id="endTime"
        v-model="modal.endTime"
        locale="en"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormTimepicker, BCard, BCardTitle, BButton, BOverlay,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import timegrid from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import Swal from 'sweetalert2'
import scheduleStoreModule from './scheduleStoreModule'
import programsStoreModule from '../programs/programsStoreModule'

export default {
  name: 'Schedule',
  components: {
    BCardTitle,
    BModal,
    BFormTimepicker,
    BCard,
    BButton,
    FullCalendar,
    BOverlay,
  },
  data() {
    return {
      programs: [],
      schedule: [],
      loadingPrograms: false,
      modal: {
        startTime: null,
        endTime: null,
      },
      selectedEvent: null,
      calendarApi: null,
      calendarOptions: {
        plugins: [timegrid, interactionPlugin],
        initialView: 'timeGridWeek',
        views: {
          timeGridWeek: {
            dayHeaderFormat: { weekday: 'long' },
          },
        },
        firstDay: 1,
        editable: true,
        droppable: true,
        allDaySlot: false,
        eventReceive: this.onEventReceive,
        events: [],
        headerToolbar: false,
        eventClick: this.onEventClick,
      },
    }
  },
  async mounted() {
    this.registerStore()
    this.registerProgramsStore()
    await this.fetchProgrammers()
    await this.fetchSchedule()

    this.initDraggable()
  },
  destroyed() {
    this.$store.unregisterModule('schedule')
    this.$store.unregisterModule('programs')
  },
  methods: {
    async saveSchedule() {
      try {
        const calendarApi = this.$refs.fullCalendar.getApi()
        const events = calendarApi.getEvents()
        console.log(events)
        const data = events.map(item => ({
          programId: item.extendedProps.programId,
          startTime: String(item.start),
          endTime: String(item.end),
          dayOfWeek: item.start.getDay(),
        }))
        await this.$store.dispatch('schedule/saveSchedule', { item: data })

        Swal.fire(
          'Done!',
          'Schedule updated!',
          'success',
        )
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>',
        })
      }
    },
    handleModal() {
      const calendarApi = this.$refs.fullCalendar.getApi()
      const newEvent = {
        title: this.selectedEvent.name,
        start: new Date(this.selectedEvent.fullDate).setHours(...this.modal.startTime.split(':')),
        end: new Date(this.selectedEvent.fullDate).setHours(...this.modal.endTime.split(':')),
        programId: this.selectedEvent.id,
      }
      calendarApi.addEvent(newEvent)
      // this.programs = this.programs.filter(item => item.id !== this.selectedEvent.id)
    },
    initDraggable() {
      const containerEl = document.getElementById('external-events')

      // eslint-disable-next-line no-new
      new Draggable(containerEl, {
        itemSelector: '.fc-event',
        eventData(eventEl) {
          return {
            title: eventEl.innerText,
          }
        },
      })
    },
    async fetchProgrammers() {
      this.programs = await this.$store.dispatch('programs/fetchPrograms', { page: 1, limit: 100, q: '' })
    },
    async fetchSchedule() {
      this.loadingPrograms = true

      const scheduleData = await this.$store.dispatch('schedule/fetchSchedule', { page: 1, limit: 100, q: '' })
      console.log(scheduleData)
      this.schedule = scheduleData.map(this.formatEvents)

      this.calendarOptions.events = this.schedule
      // this.programs = this.programs.filter(item1 => !this.calendarOptions.events.some(item2 => item1.id === item2.programId))

      this.loadingPrograms = false
    },
    registerStore() {
      this.$store.registerModule('schedule', scheduleStoreModule)
    },
    registerProgramsStore() {
      this.$store.registerModule('programs', programsStoreModule)
    },
    onEventReceive(info) {
      this.selectedEvent = this.programs.find(item => item.name === info.event.title)
      const date = new Date(info.event.start)
      this.selectedEvent.fullDate = date

      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      const second = date.getSeconds().toString().padStart(2, '0')
      const time = `${hour}:${minute}:${second}`

      this.modal.startTime = time

      info.event.remove()
      this.selectedEvent.startTime = time
      this.$bvModal.show('my-modal')
    },
    async onEventClick(info) {
      Swal.fire({
        title: `Do you want to delete ${info.event.title}?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then(async result => {
        if (result.isConfirmed) {
          info.event.remove()
          // const lastEvents = this.$refs.fullCalendar.getApi().getEvents()

          await this.fetchProgrammers()
          // this.programs = this.programs.filter(item1 => !lastEvents.some(item2 => item1.id === item2.extendedProps.programId))

          this.initDraggable()
        }
      })
    },
    formatEvents(data) {
      return {
        id: data.id,
        title: data.Program.name,
        start: new Date(data.startTime),
        end: new Date(data.endTime),
        programId: data.Program.id,
      }
    },
  },
}
</script>

<style>
.swal2-styled.swal2-confirm{
  margin-right: 10px;
}
</style>
