import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPrograms(ctx, queryParams) {
      console.log(queryParams)
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/admin/radio/program?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    fetchProgram(ctx, id) {
      const uri = `/admin/radio/program/${id}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    updateProgram(ctx, payload) {
      const uri = '/admin/radio/program'

      return new Promise((resolve, reject) => {
        axios
          .put(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    createProgram(ctx, payload) {
      const uri = '/admin/radio/program'

      return new Promise((resolve, reject) => {
        axios
          .post(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    deleteProgram(ctx, id) {
      const uri = `/admin/radio/program?id=${id}`

      return new Promise((resolve, reject) => {
        axios
          .delete(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
  },
}
